import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormControl, Form, Button } from 'react-bootstrap';
import { TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb";
import { IoSearchOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import { BiChat } from "react-icons/bi";
import Select from 'react-select';
import { LuSend } from "react-icons/lu";
import Accordion from 'react-bootstrap/Accordion';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { FiSend } from "react-icons/fi";
import backgroundImage from '../images/backgroudImage.jpeg'
import { LuMessageSquare } from "react-icons/lu";
import TemplatePopup from './TemplatePopup';
import { FaTimes } from "react-icons/fa";
import { PiImagesLight } from "react-icons/pi";
import { ImFileVideo } from "react-icons/im";
import { LuMailPlus } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GrAttachment } from "react-icons/gr";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DELETE, GET, POST } from '../Apis/config/RequestType';
import { communication_contact, user_chat_history_v1, getTemplateFromTable, getAllChatContact_v1, sendMessage_v1, getContactNotes_v1, createContactNotes_v1, uploadFile_v1, downloadMedia_v1, deleteMedia_v1, getMetaTemplate_v1, createContact_v1, badgeCounter_v1, updateChatstatus, chat_history } from '../Apis/config/Url';
import Caller from '../Apis/config/Caller';
import io from 'socket.io-client';
import { BASE_URL } from '../Apis/config/Url'
import { MdOutlineSpeakerNotes } from "react-icons/md"
import { RxCross2 } from "react-icons/rx";
import { GoDotFill } from "react-icons/go";
import { ClipLoader, HashLoader, PuffLoader } from 'react-spinners';
import { MdLocalPhone } from "react-icons/md";
import { VscLinkExternal } from "react-icons/vsc";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { RiCheckDoubleFill } from "react-icons/ri";
import axios from 'axios';
import { RiCheckFill } from "react-icons/ri";
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';



import { requestFirebaseNotificationPermission, onMessageListener } from '../firebaseConfig';
import socketIOClient from 'socket.io-client';

import '../components/css/Layout.css'
import { socket } from '../App';
import { Howl } from 'howler';

const ENDPOINT = BASE_URL;
const moment = require('moment');

// const socket = io(`${BASE_URL}`);

const countryCodes = [
    { label: '+91', value: '+91' },
];

const Layout = ({ dematData }) => {
    const chatContainerRef = useRef(null);
    const [detailNoteBtn, setDetailNoteBtn] = useState("Details");
    const [currentUser, setCurrentUser] = useState([]);
    const [showContactList, setShowContactList] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [IsMessagePresent, setIsmessagePresent] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [activeItemAllChat, setActiveItemAllChat] = useState('All');
    const [activeItemMyChat, setActiveItemMyChat] = useState('All');
    const [selectedContact, setSelectedContact] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [attachmentclicked, setAttachmentClicked] = useState(false);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);
    const [isTemplatePopupVisible, setIsTemplatePopupVisible] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [userChatHistory, setUserChatHistory] = useState([]);
    const [isNotRead, setIsNotRead] = useState()
    const [message, setMessages] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);
    const [chatMessage, setChatMessage] = useState(``);
    const [selectedImagePreview, setSelectedImagePreview] = useState('')
    const [typeMessageImage, setTypeMessageImage] = useState('');

    const [selectedImage, setSelectedImage] = useState(null);
    const [media_url_path, setMediaUrlPath] = useState("");
    const [imageId, setImageId] = useState(null);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [documentId, setDocumentId] = useState(null);
    const [documentName, setDocumentName] = useState("");

    const [videoId, setVideoId] = useState("");
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoName, setVideoName] = useState("");

    const [filesize, setFileSize] = useState('');
    const [filetype, setFileType] = useState('')

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState('All Chats')

    const [contactPage, setContactPage] = useState(1);
    const [totalContactPages, setTotalContactPages] = useState(null);
    const contactdivRef = useRef(null);
    const [tempContactData, setTempContactData] = useState([]);

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');

    //   Contact search 

    const [searchContactData, setSearchContactData] = useState();

    const [totalChatPages, setTotalChatPages] = useState(0);
    const [chatMessagesTemp, setChatMessagesTemp] = useState([]);
    const [chatPage, setChatPage] = useState(1);

    // Badge count
    const [readBadgeCount, setReadBadegCount] = useState(null);
    const [unreadBadegCount, setUnreadBadegCount] = useState(null);
    const [totalBadegCount, setTotalBadegCount] = useState(null);

    const [contactIndex, setContactIndex] = useState(null);

    const [cliploading, setClipLoading] = useState(false);

    const [navbarHeight, setNavbarHeight] = useState(58);

    const [isTokenFound, setTokenFound] = useState(false);
    const [data, setData] = useState();
    const [currentFileHandler, setCurrentFileHandler] = useState(null); // State to store current file handler

    const [noteDescription, setNoteDescription] = useState("");
    const [notesData, setNotesData] = useState([]);

    const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [downloadLoader, setDownloadLoader] = useState(false);

    const [numPages, setNumPages] = useState(null);

    const [chatType, setChatType] = useState('allchat')
    const [clicked, setClicked] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0);
    const location = useLocation();
    const [UserType, setUserType] = useState('');
    const [isDematRedirect, setIsDematRedirect] = useState(false);
    const [onceRedirecting, setOnceRedirecting] = useState(true);
    const contactDematRef = useRef(null);
    const [isRedirect, setIsRedirect] = useState(false);
    const selectedContactRef = useRef(null);
    const [notification_SoundCondition, setNotification_soundCondition] = useState(true);
    // const notification_sound = new Audio('/notificacion.MP3')
    const notification_sound = new Howl({
        src: ['/notificacion.mp3'],
    });

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };



    // const UserType = Cookies.get('auth_usertype');

    // move the contact to top
    const moveContact = (fromIndex, toIndex) => {
        if (toIndex >= 0 && toIndex < tempContactData.length && fromIndex !== toIndex) {
            const updatedContacts = [...tempContactData];
            const [movedItem] = updatedContacts.splice(fromIndex, 1);
            updatedContacts.splice(toIndex, 0, movedItem);
            setTempContactData(updatedContacts);
        }
    };


    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleSendTemplateClick = async () => {
        setIsPopupVisible(true);
    };

    const handleKeyDown = (event) => {

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (chatMessage) {
                handleSendMessage();
            }
            setChatMessage(``)
            const textarea = textareaRef.current;
            if (textarea) {
                textarea.style.height = '41px';
            }
        }

    };

    const handleChatMessage = (event) => {
        const value = event.target.value;
        setChatMessage(`${value}`);

    }

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const handleNewChatClick = () => {
        setShowContactList(false);
    };

    const handleStartChat = async () => {
        const formData = new FormData()
        formData.append('first_name', firstname)
        formData.append('last_name', lastname)
        formData.append("mobile", phoneNumber)
        formData.append("product", 'Course')
        const create_contact = await Caller(createContact_v1, formData, POST, false, true)
        if (create_contact && create_contact.success && create_contact.data) {
            setTempContactData(prevContacts => [create_contact.data, ...prevContacts]);
            enqueueSnackbar('Contact Added Successfully.', { variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });

            // Assuming create_contact.data contains the new contact object
        } else {
            enqueueSnackbar(create_contact.message, { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            // Handle the case where the response structure is unexpected or data is missing
        }

        setPhoneNumber('');
        setShowContactList(true);
        setFirstName('')
        setLastName('')


    };

    useEffect(() => {
        socket.on("message recieved", (newMessageRecieved) => {
            notification_sound.play();
            setMessages([...message, newMessageRecieved]);
        });
    });

    useEffect(() => {
        setClipLoading(true)
        setUserType(Cookies.get('auth_usertype'));
        if (Cookies.get('auth_usertype') == 1 || Cookies.get('auth_usertype') == 2 || Cookies.get('auth_usertype') == 3 || Cookies.get('auth_usertype') == 4) {
            setChatType('allchat');
            setActiveItemMyChat('')
        } else {
            setChatType('mychat');
        }

        let fetchData = async () => {
            const params = new URLSearchParams();

            if (activeItemAllChat) {
                params.append('status', activeItemAllChat);
                // params.append('chat_type', 'allchat')
                params.append('chat_type', chatType)
                // params.append('chat_type', Cookies.get('auth_usertype') == 1 || Cookies.get('auth_usertype') == 2 || Cookies.get('auth_usertype') == 3 || Cookies.get('auth_usertype') == 4 ? 'allchat' : 'mychat')
            }
            if (searchQuery) {
                params.append('search', searchQuery);
            }
            let data = await Caller(`${getAllChatContact_v1}?${params.toString()}&page=${contactPage}`, '', GET, false, false);
            setClipLoading(false)

            setContactData(data?.data?.data);
            setTempContactData(data?.data?.data)
            setTotalContactPages(data?.data?.totalPages);

        }
        fetchData()

        let fetchBadgeData = async () => {
            let badgeData = await Caller(`${badgeCounter_v1}?status=${activeItemAllChat}&chat_type=${chatType}`, '', GET, false, false);
            setReadBadegCount(badgeData?.data?.read_count);
            setUnreadBadegCount(badgeData?.data?.unread_count);
            setTotalBadegCount(badgeData?.data?.total_count);

        }
        fetchBadgeData()
    }, []);

    const loadChatHistory = async (item, page) => {
        let userChatHistory = await Caller(`${user_chat_history_v1}?mobile=${item.mobile}&page=1`, '', GET, false, false);
        return userChatHistory.data;
    };

    // make unread badeg to 0 when we click on contact.
    const updateUnreadCount = async (id) => {
        const updatedData = tempContactData.map(item => {
            if (item.id === id) {
                // Change the unread_message_count to 0 for the object with id
                return { ...item, unread_message_count: 0 };
            }
            return item;
        });

        // Update the state with the new array
        setTempContactData(updatedData);

        let changeChatStatus = await Caller(`${updateChatstatus}?id=${id}`, null, POST, false, true);

    };

    const handleClicked = async (item, index) => {
        if (currentUser.id === item.id) { return }
        setContactIndex(index);
        setChatMessagesTemp([]);
        setNotesData([]);
        updateUnreadCount(item.id);
        setDetailNoteBtn('Details');
        setCurrentUser(item);
        setSelectedContact(item);
        setChatPage(1);
        //--------
        setNoteDescription('');
        setChatMessage(``);
        setCurrentFileHandler(null);
        setTypeMessageImage('');
        setSelectedImagePreview('');
        setSelectedImage(null);
        setSelectedDocument(null);
        setSelectedVideo(null);
        setFileSize('');
        setFileType('');
        setMediaUrlPath('');
        setImageId(null);
        setDocumentId(null);
        setDocumentName('');
        setVideoId('');
        setVideoName('');

        let initialChatHistory = await loadChatHistory(item, 1);

        const filterIncomingMessagesToday = async (data) => {
            const startOfDay = moment().startOf('day');
            const endOfDay = moment().endOf('day');

            return data.filter(item => {
                const updatedAt = moment(item.updated_at);
                return item.message_nature === 'Incoming' && updatedAt.isBetween(startOfDay, endOfDay, null, '[]');
            });
        };

        let incomingMessagesToday = await filterIncomingMessagesToday(initialChatHistory.data);
        const isMessagePresentToday = item.is_today_message_present === 1;
        // setIsmessagePresent(isMessagePresentToday);

        setIsmessagePresent(incomingMessagesToday.length > 0 || isMessagePresentToday);
        setUserChatHistory(initialChatHistory.data);
        setChatMessagesTemp(initialChatHistory.data)
        setIsNotRead(initialChatHistory.unReadCount);
        setTotalChatPages(initialChatHistory.totalPages);
        setBadge(chatType);
        // socket.emit("join chat", item.id);

    };

    const handleCancelClick = () => {
        setShowContactList(true);
    }

    const handleClickAllChat = async (item) => {
        // Perform the required operations before making the API call
        setClipLoading(true);
        setSearchQuery('');
        setSelectedContact(null);
        setCurrentUser([]);
        setContactPage(1);

        const params = new URLSearchParams();
        if (item) {
            params.append('status', item);
            params.append('chat_type', chatType)
            let data = await Caller(`${getAllChatContact_v1}?${params.toString()}&page=${contactPage} `, '', GET, false, false);
            setClipLoading(false)
            setSearchContactData()
            setTempContactData(data?.data?.data);
        }


    };

    const handleClickAllChaty = (item) => {
        setActiveItemAllChat(item); // Update the state
        handleClickAllChat(item); // Use the item directly to avoid delay
    };

    const handleClickMyChat = async (item) => {
        setClipLoading(true)
        setSearchQuery('');
        setSelectedContact(null);
        setCurrentUser([]);
        setContactPage(1);
        setSearchContactData()

        const params = new URLSearchParams();
        if (item) {
            params.append('status', item);
            params.append('chat_type', chatType)
            let data = await Caller(`${getAllChatContact_v1}?${params.toString()}&page=${contactPage}`, '', GET, false, false);
            setClipLoading(false)
            setTempContactData(data?.data?.data);

        }

    };

    const handleClickMyChaty = (item) => {
        setActiveItemMyChat(item);
        handleClickMyChat(item);
    };


    const getNavbarStyle = (item) => ({
        height: '35px',
        width: '100%',
        backgroundColor: activeItemAllChat === item ? '#cfe2ff' : 'transparent',
        cursor: 'pointer',
        paddingLeft: '30px'
    });
    const getNavbarStyleMyChat = (item) => ({
        height: '35px',
        width: '100%',
        backgroundColor: activeItemMyChat === item ? '#cfe2ff' : 'transparent',
        cursor: 'pointer',
        paddingLeft: '30px'
    });

    // const filteredContacts = contactData?.filter((item) =>
    //     item?.first_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    // );

    const handleSearchClick = () => {
        setIsSearchVisible(true)
    }
    const handleClearSearch = () => {
        setSearchQuery('');
        setIsSearchVisible(false);
        if (chatType === 'allchat') {
            handleClickAllChaty(activeItemAllChat)
        }
        else {
            handleClickMyChaty(activeItemMyChat)
        }
    };
    const noFocusStyle = {
        boxShadow: 'none',
        outline: 'none',
        color: 'inherit',
        backgroundColor: 'inherit',
    };
    const popupStyles = {
        position: 'fixed',
        bottom: '70px',
        right: '18%',
        width: '300px',
        height: '160px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        padding: '10px',
        zIndex: 9999,
        border: '1px solid #E5E4E2',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '5px',
    };

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        border: 'none',
        background: 'none',
    };

    const iconTextStyle = {
        fontSize: '11px',
        marginTop: '5px',
    };

    const handleattachment = (event) => {
        event.stopPropagation();
        setAttachmentClicked(!attachmentclicked)
    }

    // Function to handle button click and set file handler
    const handleButtonClick = (acceptType, fileHandler) => {
        setCurrentFileHandler(() => fileHandler); // Set current file handler
        fileInputRef.current.accept = acceptType; // Set accepted file types
        fileInputRef.current.click(); // Trigger click event on file input
    };

    // Function to handle file input change
    const handleFileInputChange = (event) => {
        if (currentFileHandler) {
            currentFileHandler(event); // Invoke current file handler
        }
    };

    const handleFileChange = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            const extension = file.type.split('/').pop();
            if (['jpg', 'jpeg', 'png'].includes(extension) == false) {
                enqueueSnackbar('Only jpg, jpeg, png files are allowed!', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            }
            if (file.size > 5 * 1024 * 1024) {
                enqueueSnackbar('File size is too large. Max allowed limit is 5 MB.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            } else {
                const reader = new FileReader();

                reader.onload = () => {
                    setTypeMessageImage(reader.result);

                };
                reader.readAsDataURL(file);
                setSelectedImagePreview(file.name)
                setSelectedImage(URL.createObjectURL(file)); // Set the image preview URL
                setSelectedDocument(null)
                setSelectedVideo(null)
                setFileSize(Math.round(file.size / 1024)); // Convert to KB and round to nearest integer
                setFileType(file.type.split('/')[1].toUpperCase());

                const formData = new FormData();

                formData.append('file', file);


                // upload file to get id
                await Caller(uploadFile_v1, formData, POST, false, true).then(async (resData) => {

                    setMediaUrlPath(resData.data.media_url_path || "");
                    setImageId(resData.data.data.id || "");
                    setLoading(false);
                    setDocumentId(null)
                    setDocumentName('')
                    setVideoId('')
                    setVideoName('')
                });

            }
        }
        event.target.value = null;
    };

    const handleDocumentChange = async (event) => {
        setLoading(true);
        const file = event.target.files[0];

        if (file) {
            const fileType = file.type.split('/').pop();
            const extension = fileType.split('.').pop().toLowerCase();
            let filetypedisplay = file.type.split('/')[1]
            if (extension === 'plain') {
                filetypedisplay = "TXT"
            }
            if (extension === 'sheet') {
                filetypedisplay = "XLSX"
            }

            // const extension = file.type.split('/').pop();
            if (['pdf', 'plain', 'xlsx', 'sheet'].includes(extension) == false) {
                enqueueSnackbar('Only PDF, XLSX, TXT files are allowed!', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            }

            if (file.size > 5 * 1024 * 1024) {
                enqueueSnackbar('File size is too large. Max allowed limit is 5 MB.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    setTypeMessageImage(reader.result);
                };
                reader.readAsDataURL(file);
                setSelectedDocument(file);
                setDocumentName(file.name);
                setSelectedImage(null)
                setSelectedVideo(null)
                setFileSize(Math.round(file.size / 1024)); // Convert to KB and round to nearest integer
                // setFileType(file.type.split('/')[1].toUpperCase());
                setFileType(filetypedisplay.toUpperCase());

                const formData = new FormData();
                formData.append('file', file);

                // upload document to get id
                await Caller(uploadFile_v1, formData, POST, false, true).then(async (resData) => {
                    setMediaUrlPath(resData.data.media_url_path);
                    setDocumentId(resData.data.data.id);
                    setLoading(false);
                    setImageId(null)
                    setSelectedImagePreview('')
                    setVideoId('')
                    setVideoName('')
                });
            }
        }
        event.target.value = null;
    };

    const handleVideoChange = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            const extension = file.type.split('/').pop();
            if (['gif', 'mov', 'mp4'].includes(extension) == false) {
                enqueueSnackbar('Only gif, mov, mp4 files are allowed!', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            }

            if (file.size > 25 * 1024 * 1024) {
                enqueueSnackbar('File size is too large. Max allowed limit is 25 MB.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                setLoading(false);
                event.target.value = null;
                return;
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    setTypeMessageImage(reader.result);
                };
                reader.readAsDataURL(file);
                setSelectedVideo(file);
                setVideoName(file.name)
                setSelectedDocument(null)
                setSelectedImage(null)
                setFileSize(Math.round(file.size / 1024)); // Convert to KB and round to nearest integer
                setFileType(file.type.split('/')[1].toUpperCase());


                const formData = new FormData();
                formData.append('file', file);

                // upload document to get id
                await Caller(uploadFile_v1, formData, POST, false, true).then(async (resData) => {
                    setMediaUrlPath(resData.data.media_url_path);
                    setVideoId(resData.data.data.id);
                    setLoading(false);
                    setImageId(null)
                    setSelectedImagePreview('')
                    setDocumentId(null)
                    setDocumentName('')
                });
            }
        }
        event.target.value = null;

    };

    const handleCloseTemplatePopup = () => {
        setIsTemplatePopupVisible(false);
    };
    const handletemplateiconclicked = () => {
        setIsTemplatePopupVisible(true)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (attachmentclicked && !event.target.closest('.attachment-popup')) {
                setAttachmentClicked(false);
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [attachmentclicked]);


    const handleSendMessage = async () => {
        if (clicked) return; // Prevent multiple clicks
        setClicked(true); // Disable the button

        const formData = new FormData()
        formData.append("mobile", selectedContact?.mobile)
        formData.append('whatsapp_contact_id', selectedContact?.id);
        formData.append('message_nature', 'Outgoing');
        formData.append('message', chatMessage)
        formData.append('message_type', "single");

        if (imageId) {
            formData.append('image', imageId);
            formData.append('media_url_path', media_url_path ?? "");
        }

        if (documentId) {
            formData.append('document', documentId);
            formData.append('document_name', documentName ?? "");
            formData.append('media_url_path', media_url_path ?? "");
        }

        if (videoId) {
            formData.append('video', videoId);
            formData.append('media_url_path', media_url_path ?? "");
        }

        try {
            let send_message = await Caller(sendMessage_v1, formData, POST, false, true)
            if (send_message.success == true) {
                moveContact(contactIndex, 0);

                // socket.emit("new message", send_message);
                setMessages([...message, send_message]);
                setChatMessage(``)
                setTypeMessageImage('')
                setSelectedImagePreview('')
                setDocumentName('')
                setMediaUrlPath('')
                setImageId('')
                setSelectedDocument(null);
                setDocumentId(null);
                setSelectedVideo(null);
                // handleClicked(selectedContact)
                setVideoId("")
                setVideoName("")
                const textarea = textareaRef.current;
                if (textarea) {
                    textarea.style.height = '41px';
                }

            } else {
                enqueueSnackbar('Message Send Failed.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            }
        } catch (error) {

            enqueueSnackbar('Message Send Failed.', {
                variant: 'error',
                autoHideDuration: 2000,
                TransitionProps: { direction: "left" },
                anchorOrigin: { horizontal: "right", vertical: 'top' }
            });



        }
        finally {
            setClicked(false); // Re-enable the button
        }
    }

    const noteBtnHandler = async () => {
        setDetailNoteBtn("Notes");

        await Caller(getContactNotes_v1 + "?id=" + selectedContact?.id || "", '', GET, false, false).then((res) => {
            if (res.success === true) {
                setNotesData(res.data.data);
            }
        })

    }

    const noteHandler = (e) => {
        setNoteDescription(e.target.value);

    }

    const saveNote = async (event) => {

        const formData = new FormData();
        formData.append('contact_id', selectedContact?.id || "");
        formData.append('description', noteDescription);

        // console.table([...formData])

        let create_notes = await Caller(createContactNotes_v1, formData, POST, false, true)
        if (create_notes.success == true) {
            enqueueSnackbar('Note Added successfully!', { variant: 'success', autoHideDuration: 1000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
            setNoteDescription("");
        }

    }

    const allTrue = () => {
        if (noteDescription !== "") {
            return false
        } else {
            return true
        }
    }

    const handleKeyPress = (e) => {
        // Allow only numbers and control keys (like backspace)
        if (
            // Check if the input is a number
            /[0-9]/.test(e.key) &&
            (
                // Allow the first digit to be 6, 7, 8, or 9
                (e.target.value === "" && ["6", "7", "8", "9"].includes(e.key)) ||
                // Allow other digits after the first digit is 6, 7, 8, or 9
                (e.target.value !== "" && /[0-9]/.test(e.key))
            )
        ) {
            // Input is valid
        } else if (
            // Allow control keys (like backspace, delete, arrow keys)
            ["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)
        ) {
            // Control key is allowed
        } else {
            // Prevent default for all other keys
            e.preventDefault();
        }
    };




    const downloadBtnClick = async (media_id, index) => {
        setLoadingIndex(index)
        setDownloadLoader(true)
        // setDownloadBtnLoader(true);
        const formData = new FormData();
        formData.append('media_id', media_id);

        await Caller(downloadMedia_v1, formData, POST, false, true).then(async (response) => {

            setDownloadLoader(false)

            const name = response.data.media_url_path.startsWith('/') ? response.data.media_url_path.substring(1) : response.data.media_url_path;
            const relativePath = name.startsWith('var/www/vhosts/backend/') ? name.replace('var/www/vhosts/backend/', '') : name;
            const response1 = await fetch(`${BASE_URL}${relativePath}`);

            const pdfData = await response1.blob();
            const url = URL.createObjectURL(pdfData);

            let a = document.createElement('a');
            a.href = url;
            let fileNameDownload = relativePath.split("/").pop();
            const fileNameBeforeExtension = fileNameDownload.split('.')[0];

            let extensionDownload = fileNameDownload.split('.').pop();
            if (extensionDownload === 'sheet') {
                extensionDownload = 'xlsx'
            }
            if (extensionDownload.includes('plain')) {
                extensionDownload = 'txt';
            }

            a.setAttribute(
                'download',
                // name.split("/").pop(),
                fileNameBeforeExtension + '.' + extensionDownload,
            );

            // Append to html link element page
            document.body.appendChild(a);

            // Start download
            a.click();

            // Clean up and remove the link
            a.parentNode.removeChild(a);

            // delete media after downloading
            const formData1 = new FormData();
            formData1.append('media_name', response.data.media_url_path);
            await Caller(deleteMedia_v1, formData1, POST, false, true).then((res) => {
                setDownloadBtnLoader(false);
                setLoadingIndex(null)

            })

        })

    }

    // Scroller Contanct 

    const handleContactScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (Math.floor(scrollBottom) <= 5 && contactPage < totalContactPages) {

            setContactPage(Number(contactPage + 1))

            let data = await Caller(`${getAllChatContact_v1}?page=${Number(contactPage) + 1}&search=${searchQuery}`, '', GET, false, false);

            if (searchQuery) {
                setSearchContactData(prevContacts => [...searchContactData, ...data.data.data])
            }
            else {
                setTempContactData(prevContacts => [...tempContactData, ...data.data.data]);

            }
            if (contactPage === totalContactPages) {
                setContactPage(1)
            }


        }

    };

    const handleContactSearch = async (e) => {
        setSearchQuery(e.target.value)

        const params = new URLSearchParams();
        if (chatType === 'allchat') {
            if (activeItemAllChat) {
                params.append('status', activeItemAllChat)
                params.append('search', e.target.value);
                if (e.target.value || chatType) {
                    let data = await Caller(`${getAllChatContact_v1}?${params.toString()}&chat_type=${chatType}`, '', GET, false, false);

                    setTempContactData([])
                    setSearchContactData(data.data.data)
                    setTotalContactPages(data.data.totalPages);
                }
            } else { setChatType('mychat') }
        }

        if (chatType === 'mychat') {
            if (activeItemMyChat) {
                params.append('status', activeItemMyChat)
                params.append('search', e.target.value);
                if (e.target.value || chatType) {
                    let data = await Caller(`${getAllChatContact_v1}?${params.toString()}&chat_type=${chatType}`, '', GET, false, false);

                    setTempContactData([])
                    setSearchContactData(data.data.data)
                    setTotalContactPages(data.data.totalPages);
                }
            } else { setChatType('allchat') }
        }

    }

    const handleChatScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        // if (Math.floor(scrollTop) == 0 && chatPage < totalChatPages) {
        if (Math.floor(scrollTop) <= 150 && chatPage < totalChatPages) {
            const oldScrollHeight = e.target.scrollHeight;
            setChatPage(Number(chatPage + 1));
            let data = await Caller(`${user_chat_history_v1}?mobile=${selectedContact.mobile}&page=${Number(chatPage + 1)}`, '', GET, false, false);

            setTotalChatPages(data.data.totalPages);
            setChatMessagesTemp([...data.data.data, ...chatMessagesTemp])

            // Adjust the scroll position
            setTimeout(() => {
                const newScrollHeight = e.target.scrollHeight;
                const addedHeight = newScrollHeight - oldScrollHeight;
                e.target.scrollTop = addedHeight;
            }, 0); // Timeout to ensure DOM updates have been applied


        }

        if (Math.floor(scrollBottom) <= 0) {
            updateUnreadCount(selectedContact.id)

            // set the badge count
            let badgeData = await Caller(`${badgeCounter_v1}?status=${activeItemAllChat}&chat_type=${chatType}`, '', GET, false, false);
            setReadBadegCount(badgeData.data.read_count);
            setUnreadBadegCount(badgeData.data.unread_count);
            setTotalBadegCount(badgeData.data.total_count);

        }

    };

    // Scroll to bottom on initial render
    useEffect(() => {

        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }

    }, [userChatHistory]);


    const handleFirstNameChange = (e) => {
        const newFirstName = e.target.value.replace(/\s/g, '');
        setFirstName(newFirstName);
        if (newFirstName === '') {
            setLastName('');
        }
    };

    const getFileExtension = (url) => {
        const lastDotIndex = url.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return null;
        }
        // const extension = url.slice(lastDotIndex + 1);
        let extension = url.slice(lastDotIndex + 1);

        if (extension === 'plain%3Bcharset%3Dutf-8') {
            extension = 'TXT'
        }

        if (extension === 'sheet') {
            extension = 'xlsx'
        }
        if (extension === 'plain') {
            extension = 'TXT'
        }

        return extension.toLowerCase();
    }

    const captureData = async (data) => {

        let currentTime = moment().format('YYYY-MM-DD HH:mm:ss')

        if (data?.data?.data_type === 'notes') {

            let notes_message = JSON.parse(data?.data?.new_message);
            if (Number(currentUser.id) === Number(notes_message.contact_id)) {
                setNotesData((prevNotesData) => [notes_message, ...prevNotesData]);
            }
        }

        // Updating the message status

        if (data?.data?.type === "status_update") {
            const updateData = JSON.parse(data.data.new_message);
            const { chat_id, status } = updateData;

            const chatUpdatedData = chatMessagesTemp.map(item =>
                item.chat_id === chat_id ? { ...item, status } : item
            );

            await setChatMessagesTemp(chatUpdatedData);
        }

        if (data.data.type == "Incoming") {
            let new_message = JSON.parse(data?.data?.new_message);
            if (notification_SoundCondition) {
                notification_sound.play();
            }

            if (Number(currentUser.id) === Number(new_message.whatsapp_contact)) {
                await setChatMessagesTemp((prevTemplateData) => [...prevTemplateData, JSON.parse(data?.data?.new_message)]);
                setIsmessagePresent(true);



            }

            // Updating the badge count for contact
            const updatedData = tempContactData.map(item => {
                if (item.id === Number(new_message.whatsapp_contact)) {
                    return { ...item, unread_message_count: Number(item.unread_message_count + 1), last_message: new_message.whatsapp_message, updated_at: currentTime };

                }
                return item;
            });

            setTempContactData(updatedData);

        }

        if (data.data.type == "Outgoing") {
            let new_message = JSON.parse(data?.data?.new_message);

            if ((Number(currentUser.id) === Number(new_message.whatsapp_contact)) || (currentUser.mobile == new_message.mobile)) {
                await setChatMessagesTemp((prevTemplateData) => [...prevTemplateData, JSON.parse(data?.data?.new_message)]);
            }

            const index = contactData.findIndex(item => item.id == Number(new_message.whatsapp_contact));

            // move the contact.
            moveContact(index, 0);

            // Updating the badge count for contact
            const updatedData = tempContactData.map(item => {
                if (item.id === Number(new_message.whatsapp_contact)) {
                    // return { ...item, unread_message_count: Number(item.unread_message_count + 1), last_message: new_message.whatsapp_message };
                    return { ...item, last_message: new_message.whatsapp_message, updated_at: currentTime };

                }
                return item;
            });

            setTempContactData(updatedData);

            // setting scroll to bottom
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }

        }

        await setBadge(chatType);

        if (data.data.type != "status_update") {
            let new_message = JSON.parse(data?.data?.new_message);
            if ((Number(currentUser.id) === Number(new_message.whatsapp_contact)) || (currentUser.mobile == new_message.mobile)) {
                const div = document.getElementById('myDiv');
                if (div.scrollHeight != null && div.scrollHeight > 0) {
                    div.scrollTo(0, div.scrollHeight);
                }
            }
        }

    }
    
    onMessageListener().then(payload => {
        captureData(payload);
    }).catch(err => console.log('failed: ', err));


    const setBadge = async (chatType) => {

        let badgeData = await Caller(`${badgeCounter_v1}?status=${activeItemAllChat}&chat_type=${chatType}`, '', GET, false, false);
        setReadBadegCount(badgeData?.data?.read_count);
        setUnreadBadegCount(badgeData?.data?.unread_count);
        setTotalBadegCount(badgeData?.data?.total_count);

    }

    // function to show data or time beside the last message in the contact section.
    const showDateTime = (last_message, updated_at) => {
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        // Parse both dates
        const givenMoment = moment(updated_at, 'YYYY-MM-DD HH:mm:ss');
        const currentMoment = moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss');
        const isSameDate = givenMoment.isSame(currentMoment, 'day');
        return isSameDate ? moment(updated_at).format('hh:mm a') : moment(updated_at).format('DD-MM-YYYY');
    }

    const showChatDateTime = (updated_at) => {
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        // Parse both dates
        const givenMoment = moment(updated_at, 'YYYY-MM-DD HH:mm:ss');
        const currentMoment = moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss');
        const isSameDate = givenMoment.isSame(currentMoment, 'day');
        // return !last_message ? '' : isSameDate ? moment(updated_at).format('hh:mm a') : moment(updated_at).format('DD-MM-YYYY');
        return isSameDate ? moment(updated_at).format('hh:mm a') : moment(updated_at).format('DD-MM-YYYY hh:mm a');
    }


    //-------------------------Redirect from Broadcast-------------------------//   

    useEffect(() => {
        const fetchChatHistory = async () => {
            const contact = location.state?.selectedContact;

            if (contact) {
                setCurrentUser(contact);
                setSelectedContact(contact);
                selectedContactRef.current = contact;
                setIsRedirect(true);

                let initialChatHistory = await loadChatHistory(contact, 1);

                const filterIncomingMessagesToday = async (data) => {
                    const startOfDay = moment().startOf('day');
                    const endOfDay = moment().endOf('day');
                    return data.filter(item =>
                        item.message_nature === 'Incoming' &&
                        moment(item.updated_at).isBetween(startOfDay, endOfDay, null, '[]')
                    );
                };

                let incomingMessagesToday = await filterIncomingMessagesToday(initialChatHistory.data);
                // setIsmessagePresent(incomingMessagesToday.length > 0);
                setUserChatHistory(initialChatHistory.data);
                setChatMessagesTemp(initialChatHistory.data);
                setIsNotRead(initialChatHistory.unReadCount);
                setTotalChatPages(initialChatHistory.totalPages);
            }
        };

        fetchChatHistory();
    }, [location.state]);

    useEffect(() => {

        if (isRedirect && selectedContactRef.current) {
            const timeoutId = setTimeout(() => {
                const contact = selectedContactRef.current;
                const fromIndex = tempContactData.findIndex(c => c.id === contact.id);
                const isMessagePresentTodayBroadcast = tempContactData[fromIndex]?.is_today_message_present
                setIsmessagePresent(isMessagePresentTodayBroadcast > 0 ? true : false)
                if (fromIndex !== -1) {
                    moveContact(fromIndex, 0);
                }
                setIsRedirect(false);
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [isRedirect, tempContactData]);

    //--------------Redirect from Broadcast END----------------//



    //---------Redirect from Demat lead -----------------------//   

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (onceRedirecting && dematmobile && tempContactData.length > 0) {
    //             const contact = tempContactData.find(contact => contact.mobile === dematmobile);

    //             if (contact) {
    //                 setCurrentUser(contact);
    //                 setSelectedContact(contact);
    //                 contactDematRef.current = contact;
    //                 setIsDematRedirect(true);
    //                 try {
    //                     let initialChatHistory = await loadChatHistory(contact, 1);

    //                     const filterIncomingMessagesToday = (data) => {
    //                         const startOfDay = moment().startOf('day');
    //                         const endOfDay = moment().endOf('day');

    //                         return data.filter(item => {
    //                             const updatedAt = moment(item.updated_at);
    //                             return item.message_nature === 'Incoming' && updatedAt.isBetween(startOfDay, endOfDay, null, '[]');
    //                         });
    //                     };

    //                     const incomingMessagesToday = filterIncomingMessagesToday(initialChatHistory.data);

    //                     setIsmessagePresent(incomingMessagesToday.length > 0);
    //                     setUserChatHistory(initialChatHistory.data);
    //                     setChatMessagesTemp(initialChatHistory.data);
    //                     setIsNotRead(initialChatHistory.unReadCount);
    //                     setTotalChatPages(initialChatHistory.totalPages);
    //                 } catch (error) {
    //                     console.error('Failed to load chat history:', error);
    //                 }
    //                 setOnceRedirecting(false)
    //             }
    //         }
    //     };

    //     if (tempContactData.length > 0) {
    //         fetchData();
    //     }
    // }, [dematmobile, tempContactData]);

    // useEffect(() => {
    //     if (isDematRedirect && contactDematRef.current) {
    //         const timeoutId = setTimeout(() => {
    //             const contact = contactDematRef.current;
    //             const fromIndex = tempContactData.findIndex(c => c.id === contact.id);
    //             if (fromIndex !== -1) {
    //                 moveContact(fromIndex, 0);
    //             }
    //             setIsDematRedirect(false);
    //         }, 100);

    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [isDematRedirect, tempContactData]);

    const fetchData = async () => {
        if (onceRedirecting && dematData.mobile && tempContactData.length > 0) {

            let contact = tempContactData.find(contact => contact.mobile === dematData.mobile);

            if (!contact) {
                contact = {
                    mobile: dematData.mobile,
                    first_name: dematData.first_name || '',
                    last_name: dematData.last_name !== 'null' || dematData.last_name !== 'null' ? dematData.last_name : '',
                    id: dematData.contact_id,
                    updated_at: moment(dematData.contact_updated_at).format('YYYY-MM-DD HH:mm:ss')
                };
                setTempContactData(prev => [contact, ...prev]);  // Add the new contact to the top
            }

            setCurrentUser(contact);
            setSelectedContact(contact);
            contactDematRef.current = contact;
            setIsDematRedirect(true);

            try {
                const initialChatHistory = await loadChatHistory(contact, 1);

                const filterIncomingMessagesToday = (data) => {
                    const startOfDay = moment().startOf('day');
                    const endOfDay = moment().endOf('day');

                    return data.filter(item => {
                        const updatedAt = moment(item.updated_at);
                        return item.message_nature === 'Incoming' && updatedAt.isBetween(startOfDay, endOfDay, null, '[]');
                    });
                };

                const incomingMessagesToday = filterIncomingMessagesToday(initialChatHistory.data);

                // setIsmessagePresent(incomingMessagesToday.length > 0);
                setUserChatHistory(initialChatHistory.data);
                setChatMessagesTemp(initialChatHistory.data);
                setIsNotRead(initialChatHistory.unReadCount);
                setTotalChatPages(initialChatHistory.totalPages);
            } catch (error) {
                console.error('Failed to load chat history:', error);
            }

            setOnceRedirecting(false);
        }
    };

    useEffect(() => {
        if (tempContactData?.length > 0) {
            fetchData();
        }
    }, [dematData, tempContactData]);

    useEffect(() => {
        if (isDematRedirect && contactDematRef.current) {
            const timeoutId = setTimeout(() => {
                const contact = contactDematRef.current;
                const fromIndex = tempContactData.findIndex(c => c.id === contact.id);
                const isMessagePresentTodayDemat = tempContactData[fromIndex]?.is_today_message_present
                setIsmessagePresent(isMessagePresentTodayDemat > 0 ? true : false)
                if (fromIndex !== -1) {
                    moveContact(fromIndex, 0);  // Move contact to the top
                }
                setIsDematRedirect(false);
            }, 100);

            return () => clearTimeout(timeoutId);
        }
    }, [isDematRedirect, tempContactData]);


    //-------------Redirect from demat END----------------//  

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const fullName = `${currentUser.first_name} ${currentUser.last_name === null || currentUser.last_name === 'null' ? '' : currentUser.last_name}`;
    const truncatedName = truncateText(fullName, 40);

    return (
        <Container fluid style={{ overflowY: 'hidden', height: `calc(100vh - ${navbarHeight}px)` }} >
            <Row style={{ border: '1px solid #E5E4E2', marginLeft: '37px', overflowY: 'hidden', height: `calc(100vh - ${navbarHeight}px)` }}>

                {collapsed && (
                    <style>{`#filter {
    flex: 0 0 auto;
    width: 4.66666667%;
    }
    #details {
    flex: 0 0 auto;
    width: 19.66666667%;
}
    .col-6 {
    flex: 0 0 auto;
    width: 50.66%;
}
     
`}</style>)}
                <Col xs={2} id='filter' style={{ padding: 0, border: '1px solid #E5E4E2', height: `calc(100vh - ${navbarHeight}px)` }}>
                    <div style={{ userSelect: 'none', height: '40px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: collapsed ? '0' : '' }}>
                        {!collapsed && (<div>
                            <h6>Inbox</h6>
                        </div>)}
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip" style={{ zIndex: 9999 }}>Close Filter</Tooltip>}

                            >
                                <div >
                                    <TbLayoutSidebarLeftCollapseFilled onClick={toggleCollapse} style={{ color: '#3F906D', marginLeft: 10, cursor: 'pointer', fontSize: '22px', transform: collapsed ? 'scaleX(-1)' : '' }} />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div>
                        {/* accordion */}

                        <style>
                            {`
          .accordion-button::after {
            display: none !important;
          }
          .accordion-button:not(.collapsed) {
            color: inherit !important;
            background-color: inherit !important;
          }
          .accordion-button:focus {
            box-shadow: none !important;
            outline: none !important;
          }
            .accordion-button {
            padding-right: 0 !important}
          
            .consistent-icon-size {
            font-size: 22px !important;
            color: #3F906D !important;
            flex-shrink: 0 !important;
            }
            
        `}
                        </style>

                        <Accordion defaultActiveKey="0" flush  >
                            {(UserType === '1' || UserType === '2' || UserType === '3' || UserType === '4') && collapsed && (<Accordion.Header   >
                                <BiChat className="consistent-icon-size" onClick={toggleCollapse} />
                            </Accordion.Header>)}
                            {!collapsed && (<Accordion.Item eventKey="0"  >

                                {(UserType === '1' || UserType === '2' || UserType === '3' || UserType === '4') && <Accordion.Header onClick={() => { setCollapsed(false); setActive('All Chats'); setChatType('allchat'); setBadge('allchat'); }}>
                                    <BiChat style={{ marginRight: '5px', color: '#3F906D', fontSize: '22px' }}
                                    />
                                    <span style={{ userSelect: 'none' }}> All Chats</span>
                                </Accordion.Header>}
                                {(UserType === '1' || UserType === '2' || UserType === '3' || UserType === '4') && <Accordion.Body style={{ padding: 0, marginTop: '5px' }}>

                                    <Navbar style={getNavbarStyle('All')} onClick={() => { handleClickAllChaty('All'); setActiveItemMyChat('') }}>
                                        <Container>
                                            <Navbar.Brand href="" style={{ userSelect: 'none', fontSize: '14px', color: activeItemAllChat === 'All' ? '#3F906D' : '', fontWeight: '500' }}>
                                                All
                                                {totalBadegCount > 0 && <Badge bg="" style={{ userSelect: 'none', marginLeft: '10px', backgroundColor: activeItemAllChat === 'All' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'All' ? 'white' : '#6c757d', }}>{totalBadegCount}</Badge>}
                                            </Navbar.Brand>

                                        </Container>
                                    </Navbar>

                                    <Navbar
                                        style={getNavbarStyle('Unread')}
                                        onClick={() => { handleClickAllChaty('Unread'); setActiveItemMyChat('') }}
                                    >
                                        <Container>
                                            <Navbar.Brand href="" style={{ userSelect: 'none', fontSize: '14px', color: activeItemAllChat === 'Unread' ? '#3F906D' : '', fontWeight: '500' }}>
                                                Unread
                                                {unreadBadegCount > 0 && <Badge bg="" style={{ userSelect: 'none', marginLeft: '10px', backgroundColor: activeItemAllChat === 'Unread' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'Unread' ? 'white' : '#6c757d' }}>{unreadBadegCount}</Badge>}
                                            </Navbar.Brand>

                                        </Container>
                                    </Navbar>

                                    <Navbar
                                        style={getNavbarStyle('Read')}
                                        onClick={() => { handleClickAllChaty('Read'); setActiveItemMyChat('') }}
                                    >
                                        <Container>
                                            <Navbar.Brand href="" style={{ userSelect: 'none', fontSize: '14px', color: activeItemAllChat === 'Read' ? '#3F906D' : '', fontWeight: '500' }}>
                                                Read
                                                {readBadgeCount > 0 && <Badge bg="" style={{ userSelect: 'none', marginLeft: '10px', backgroundColor: activeItemAllChat === 'Read' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'Read' ? 'white' : '#6c757d' }}>{readBadgeCount}</Badge>}
                                            </Navbar.Brand>
                                        </Container>
                                    </Navbar>

                                </Accordion.Body>}
                            </Accordion.Item>)}
                            {collapsed && (<Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <BiChat className="consistent-icon-size" onClick={toggleCollapse} />
                                </Accordion.Header>
                            </Accordion.Item>)}

                            {!collapsed && (<Accordion.Item eventKey="1" onClick={() => { setCollapsed(false); setActive('My Chats'); setChatType('mychat'); setBadge('mychat'); }}>
                                <Accordion.Header >

                                    <BiChat style={{ marginRight: '5px', color: '#3F906D', fontSize: '22px' }} />
                                    <span style={{ userSelect: 'none' }}>My Chats</span>
                                </Accordion.Header>
                                <Accordion.Body style={{ userSelect: 'none', padding: 0, marginTop: '5px' }}>

                                    <Navbar style={getNavbarStyleMyChat('All')} onClick={() => { handleClickMyChaty('All'); setActiveItemAllChat('') }}>
                                        <Container>
                                            <Navbar.Brand href="" style={{ fontSize: '14px', color: activeItemMyChat === 'All' ? '#3F906D' : '', fontWeight: '500' }}>
                                                All
                                                {totalBadegCount > 0 && <Badge bg="" style={{ marginLeft: '10px', backgroundColor: activeItemAllChat === 'All' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'All' ? 'white' : '#6c757d', }}>{totalBadegCount}</Badge>}
                                            </Navbar.Brand>
                                        </Container>
                                    </Navbar>

                                    <Navbar style={getNavbarStyleMyChat('Unread')} onClick={() => { handleClickMyChaty('Unread'); setActiveItemAllChat('') }}>
                                        <Container>

                                            <Navbar.Brand href="" style={{ fontSize: '14px', color: activeItemMyChat === 'Unread' ? '#3F906D' : '', fontWeight: '500' }}>
                                                Unread
                                                {unreadBadegCount > 0 && <Badge bg="" style={{ marginLeft: '10px', backgroundColor: activeItemAllChat === 'Unread' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'Unread' ? 'white' : '#6c757d' }}>{unreadBadegCount}</Badge>}
                                            </Navbar.Brand>
                                        </Container>
                                    </Navbar>

                                    <Navbar style={getNavbarStyleMyChat('Read')} onClick={() => { handleClickMyChaty('Read'); setActiveItemAllChat('') }}>
                                        <Container>
                                            <Navbar.Brand href="" style={{ fontSize: '14px', color: activeItemMyChat === 'Read' ? '#3F906D' : '', fontWeight: '500' }}>
                                                Read
                                                {readBadgeCount > 0 && <Badge bg="" style={{ marginLeft: '10px', backgroundColor: activeItemAllChat === 'Read' ? '#3F906D' : '#dee2e6', color: activeItemAllChat === 'Read' ? 'white' : '#6c757d' }}>{readBadgeCount}</Badge>}
                                            </Navbar.Brand>
                                        </Container>
                                    </Navbar>

                                </Accordion.Body>
                            </Accordion.Item>)}
                        </Accordion>
                    </div>
                </Col>

                <Col xs={3} style={{ padding: 0, border: '1px solid #E5E4E2', height: `calc(100vh - ${navbarHeight}px)`, position: 'relative' }}>
                    {showContactList && <div>
                        <div style={{ userSelect: 'none', height: '40px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {!isSearchVisible && (<div>
                                <h6>{active}- {activeItemAllChat || activeItemMyChat}</h6>
                            </div>)}
                            <div style={{ userSelect: 'none', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                {!isSearchVisible && (
                                    <IoSearchOutline style={{ color: '#3F906D' }} onClick={handleSearchClick} />
                                )}
                                {isSearchVisible && (
                                    <div style={{ userSelect: 'none', position: 'absolute', width: '95%' }}>
                                        <FormControl
                                            type="text"
                                            placeholder="Enter Search term"
                                            value={searchQuery}
                                            onChange={handleContactSearch}

                                            style={{ userSelect: 'none', width: '100%', height: '30px', border: 'none', outline: 'none', boxShadow: 'none' }}

                                        />
                                        <FaTimes
                                            style={{ color: '#3F906D', cursor: 'pointer', position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}
                                            onClick={handleClearSearch}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{ paddingRight: 0 }} >
                            <div className="contact_scrollbar" style={{ maxHeight: `calc(100vh - ${navbarHeight}px - 41px)`, height: `calc(100vh - ${navbarHeight}px - 40px)`, overflowY: 'auto', paddingRight: 0, scrollbarWidth: 'thin', scrollbarColor: '#888888 #f0f0f0' }} ref={contactdivRef} onScroll={handleContactScroll}>
                                {cliploading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <ClipLoader color={"#176433"} loading={cliploading} size={50} />
                                    </div>
                                ) : (
                                    (searchContactData == undefined || searchQuery == '') && tempContactData?.map((item, index) => (
                                        <ListGroup variant="flush" key={item.id}>
                                            <ListGroup.Item style={{ userSelect: 'none', height: 'auto', backgroundColor: currentUser?.id === item.id ? '#EAF3F0' : '#ffffff', borderBottom: '1px solid #E5E4E2', cursor: 'pointer' }} onClick={() => handleClicked(item, index)}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '25%' }}>

                                                        <Badge bg='' style={{ backgroundColor: "#BAE1FF", borderRadius: '50%', height: '40px', width: '40px', fontSize: '20px', fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "black", textTransform: 'uppercase' }}>{item.first_name ? item.first_name[0] : item.mobile[0]}</Badge>

                                                    </div>
                                                    <div style={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', wordBreak: 'break-word' }}>
                                                            <h6> {item.first_name || item.last_name
                                                                ? `${item.first_name ? item.first_name : ''}${item.last_name ? ' ' + item.last_name : ''}`
                                                                : item.mobile}</h6>
                                                            {item.unread_message_count > 0 && <Badge bg="success" style={{ backgroundColor: '#D5E3D1', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item.unread_message_count}</Badge>}
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <p style={{ fontSize: '14px', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '70%' }}>{item.last_message?.replace(/"/g, '').replace(/\\r\\n|\\n|\\r/g, ' ')}</p>
                                                            <p style={{ fontSize: '9px', marginBottom: 0, paddingLeft: '10px', minWidth: '50px', textAlign: 'right' }}>
                                                                {item.updated_at && showDateTime(item.last_message, item.updated_at)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    )))}



                                {searchContactData?.map((item, index) => (
                                    <ListGroup variant="flush" key={item.id}>
                                        <ListGroup.Item style={{ height: 'auto', backgroundColor: currentUser?.id === item.id ? '#EAF3F0' : '#ffffff', borderBottom: '1px solid #E5E4E2', cursor: 'pointer' }} onClick={() => handleClicked(item)}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '25%' }}>

                                                    <Badge bg='' style={{ backgroundColor: "#BAE1FF", borderRadius: '50%', height: '40px', width: '40px', fontSize: '20px', fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "black", textTransform: 'uppercase' }}>{item.first_name ? item.first_name[0] : item.mobile[0]}</Badge>

                                                </div>
                                                <div style={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{ userSelect: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {/* <h6>{item.first_name && item.last_name ? item.first_name + ' ' + item.last_name : item.mobile}</h6> */}
                                                        <h6> {item.first_name || item.last_name
                                                            ? `${item.first_name ? item.first_name : ''}${item.last_name ? ' ' + item.last_name : ''}`
                                                            : item.mobile}</h6>
                                                        {item.unread_message_count > 0 && <Badge bg="success" style={{ backgroundColor: '#D5E3D1', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item.unread_message_count}</Badge>}
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <p style={{ fontSize: '14px', marginBottom: 0 }}>{item.lastMessage}</p>
                                                        <p style={{ fontSize: '9px', marginBottom: 0 }}>{item.time}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                ))}


                            </div>
                        </div>


                        <div style={{ position: 'absolute', bottom: '10px', left: '70%', transform: 'translateX(-50%)', width: '50%', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <button style={{ userSelect: 'none', width: '100%', padding: '0.7rem', backgroundColor: '#17a37b', color: '#fff', border: 'none', borderRadius: '3px', cursor: 'pointer', zIndex: '9999', fontWeight: 'bold' }} onClick={handleNewChatClick}>
                                <LuMessageSquare style={{ userSelect: 'none', fontSize: '1.5rem', marginRight: '15px', fontWeight: 'bold' }} />
                                New Chat
                            </button>
                        </div>

                    </div>}

                    {!showContactList && (<div>

                        <div style={{ userSelect: 'none', marginLeft: '0.5rem' }}>
                            Enter customer's first name
                        </div>
                        <div style={{ userSelect: 'none', padding: '10px', display: 'flex' }}>

                            <input
                                type="text"
                                placeholder="Enter first name"
                                style={{ marginLeft: '10px', height: '38px', width: '100%', border: 'none', borderBottom: '1px solid black', outline: 'none' }}
                                value={firstname}
                                maxLength={500}
                                onChange={handleFirstNameChange}
                            />
                        </div>


                        <div style={{ userSelect: 'none', marginLeft: '0.5rem', marginTop: '1rem' }}>
                            Enter customer's Last name
                        </div>
                        <div style={{ userSelect: 'none', padding: '10px', display: 'flex' }}>

                            <input
                                type="text"
                                placeholder="Enter last name"
                                style={{ userSelect: 'none', marginLeft: '10px', height: '38px', width: '100%', border: 'none', borderBottom: '1px solid black', outline: 'none' }}
                                value={lastname}
                                disabled={!firstname}
                                maxLength={500}
                                // onChange={(e) => setLastName(e.target.value)}
                                onChange={(e) => setLastName(e.target.value.replace(/\s/g, ''))}

                            />
                        </div>

                        <div style={{ userSelect: 'none', marginLeft: '0.5rem', marginTop: '1rem' }}>
                            Enter customer's phone number
                        </div>
                        <div style={{ userSelect: 'none', padding: '10px', display: 'flex' }}>
                            <div style={{ width: '50%', }}>
                                <Select
                                    options={countryCodes}
                                    styles={{ control: (provided) => ({ ...provided, height: '40px', userSelect: 'none' }) }}
                                    defaultValue={countryCodes.find(option => option.value === '+91')}
                                />
                            </div>

                            <input
                                type="text"
                                placeholder="Enter phone number"
                                style={{ userSelect: 'none', marginLeft: '10px', height: '38px', width: '100%', border: 'none', borderBottom: '1px solid black', outline: 'none' }}
                                value={phoneNumber}
                                maxLength={10}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        <div style={{ position: 'absolute', bottom: '10px', display: 'flex', padding: '10px', width: '100%', justifyContent: 'center' }}>
                            <button style={{ userSelect: 'none', width: '40%', marginRight: '5px', backgroundColor: '#fff', borderRadius: '8px', border: '1px solid #d1d1d1', cursor: 'pointer', height: '40px', color: '#545353' }} onClick={handleCancelClick}>
                                Cancel
                            </button>
                            <button
                                style={{
                                    userSelect: 'none',
                                    width: '70%',
                                    backgroundColor: (phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber)) ? '#17a37b' : '#d1d1d1',
                                    borderRadius: '8px',
                                    border: '1px solid #d1d1d1',
                                    cursor: (phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber)) ? 'pointer' : 'not-allowed',
                                    height: '40px',
                                    color: (phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber)) ? '#fff' : '#b0aeae'
                                }}
                                onClick={handleStartChat}
                                disabled={!(phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber))}
                            >
                                Start Chat
                            </button>
                        </div>
                    </div>)}
                </Col>

                {selectedContact && (<Col xs={collapsed ? 6 : 5} style={{ padding: 0, border: '1px solid #E5E4E2', position: 'relative', height: `calc(100vh - ${navbarHeight}px)` }}>
                    <div style={{ backgroundImage: `url(${backgroundImage})`, height: '100%', display: 'flex', flexDirection: 'column', backgroundSize: 'cover' }}>

                        <div style={{ height: '40px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'white' }}>

                            <div>
                                {/* <h6>{currentUser.first_name} {currentUser.last_name}</h6> */}
                                <h6 style={{ userSelect: 'none', margin: 0, display: 'inline-block' }}>{truncatedName}</h6>
                            </div>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>

                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>

                            {typeMessageImage &&

                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#dee2e6',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>

                                    {selectedImagePreview && <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: "#8a8988",
                                        height: '10%',
                                        backgroundColor: '#FBFBFC',
                                        padding: '5px',
                                        width: '100%',
                                        top: '0px',
                                        position: 'absolute'
                                    }}>
                                        <span style={{ flexGrow: 1, textAlign: 'center' }}>{selectedImagePreview}</span>
                                        <RxCross2 style={{ fontSize: '22px', fontWeight: 'bolder', color: 'rgb(108 117 125)', cursor: 'pointer', zIndex: 10 }} onClick={() => { setSelectedImagePreview(''); setTypeMessageImage('') }} />
                                    </div>
                                    }

                                    {selectedImage && <img src={typeMessageImage} alt="Image" style={{ width: '80%', height: '60%', marginBottom: '5px', borderRadius: '5px', padding: '20px', alignContent: 'center', top: '20%', position: 'absolute' }} />}
                                    {selectedImage && <span style={{ padding: '5px', marginTop: '65%', fontSize: '12px', fontWeight: 'bold', color: '#8a8988' }}>{filesize} KB <GoDotFill /> {filetype}</span>}


                                    {loading && <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        zIndex: 99999,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <HashLoader color="#176433" size={100} />
                                    </div>}
                                </div>
                            }


                            {selectedDocument &&
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#dee2e6',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {selectedDocument && <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: "#8a8988",
                                        height: '10%',
                                        backgroundColor: '#FBFBFC',
                                        padding: '5px',
                                        width: '100%',
                                        top: '0px',
                                        position: 'absolute'
                                    }}>
                                        <span style={{ flexGrow: 1, textAlign: 'center' }}>{documentName}</span>
                                        <RxCross2 style={{ fontSize: '22px', fontWeight: 'bolder', color: 'rgb(108 117 125)', cursor: 'pointer', zIndex: 10 }} onClick={() => { setSelectedDocument(null); setDocumentId(null); setDocumentName(""); setSelectedImagePreview(''); setTypeMessageImage(''); }} />
                                    </div>
                                    }
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'

                                    }}>
                                        <IoDocumentTextOutline size={200} />
                                        <span style={{ marginTop: '65%', fontSize: '12px', fontWeight: 'bold', color: '#8a8988' }}>{filesize} KB <GoDotFill /> {filetype}</span>
                                    </div>

                                </div>
                            }


                            {selectedVideo && <div style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '16px', fontWeight: '500', color: "#8a8988", height: '10%', backgroundColor: '#FBFBFC', padding: '5px', width: '100%', top: '0px', position: 'absolute'
                            }}>
                                <span style={{ flexGrow: 1, textAlign: 'center' }}>{videoName}</span>
                                <RxCross2 style={{ fontSize: '22px', fontWeight: 'bolder', color: 'rgb(108 117 125)', cursor: 'pointer', zIndex: 10 }} onClick={() => { setSelectedVideo(''); setTypeMessageImage(''); }} />
                            </div>}

                            {selectedVideo && <video
                                controls
                                style={{
                                    width: '80%',
                                    height: '50%',
                                    marginLeft: '10%',
                                    borderRadius: '5px',
                                    alignContent: 'center',
                                    top: '20%',
                                    position: 'absolute',
                                }}
                            >
                                <source src={typeMessageImage} />
                                Your browser does not support the video tag.
                            </video>}

                            {!typeMessageImage && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '95%', opacity: 0.5 }}></div>}

                            <div style={{ position: 'relative', height: '77vh', zIndex: 1 }}>
                                {cliploading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <ClipLoader color="#176433" size={60} />
                                    </div>
                                ) : (
                                    (selectedContact && !typeMessageImage) && (

                                        <div
                                            id='myDiv'
                                            key={refreshKey}
                                            ref={chatContainerRef}
                                            onScroll={handleChatScroll}
                                            style={{ overflowY: 'scroll', scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1', padding: 10, height: `calc(100vh - 165px )` }}

                                        >

                                            {chatMessagesTemp.map((message, index) => (
                                                <div key={index} style={{ textAlign: message.message_nature === "Outgoing" ? 'right' : 'left', marginBottom: '10px' }}>
                                                    <div style={{ display: 'inline-block', maxWidth: '80%', padding: '8px', borderRadius: '10px', backgroundColor: message.message_nature === 'Outgoing' ? '#E5FFEF' : '#FFFFFF', boxShadow: '0 1px 2px rgba(0,0,0,0.2)' }}>

                                                        {message.message_type === 'template' && message?.template_image_path && (
                                                            <img src={message?.template_image_path} alt="templateImage" height="auto" style={{ borderRadius: '5px', maxHeight: '400px', maxWidth: '100%', width: '300px' }} />

                                                        )}

                                                        {message.message_type === 'image' && message.media_url_path && (
                                                            <div style={{ position: 'relative', display: 'inline-block', width: '300px', height: '200px', maxWidth: '100%' }}>
                                                                <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '5px', overflow: 'hidden', cursor: 'pointer' }} onClick={() => downloadBtnClick(message.media_id, index)} >
                                                                    <img src={`${message.media_url_path}`} alt="Testing Image Source" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        maxWidth: '100%',
                                                                        height: '100%',
                                                                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                        zIndex: 20,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {loadingIndex === index ? (
                                                                            <PuffLoader color="#12ff05" size={150} />
                                                                        ) : (
                                                                            // <IoMdDownload
                                                                            //     style={{ color: '#12ff05', fontSize: '40px', cursor: 'pointer', boxShadow: '0px 0px 10px 2px black', background: '#252422' }}
                                                                            //     onClick={() => downloadBtnClick(message.media_id, index)}
                                                                            // />
                                                                            ""
                                                                        )}

                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '300px', textAlign: 'left', overflowWrap: 'break-word', maxWidth: '100%' }}>{message.media_id}.{getFileExtension(message.media_url_path)}</div>

                                                            </div>


                                                        )}

                                                        {message.message_type === 'document' && message.media_url_path && (
                                                            <div style={{ position: 'relative', display: 'inline-block', width: '300px', height: '200px', maxWidth: '100%' }}>
                                                                <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '5px', overflow: 'hidden', cursor: 'pointer' }} onClick={() => downloadBtnClick(message.media_id, index)} >

                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexDirection: 'column',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                    }}>
                                                                        <h2>{getFileExtension(message.media_url_path).toUpperCase()}</h2>
                                                                    </div>


                                                                    {/* Overlay for the download button */}
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                        zIndex: 20,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {loadingIndex === index ? (
                                                                            <PuffLoader color="#12ff05" size={150} />
                                                                        ) : (
                                                                            ""
                                                                        )}

                                                                    </div>
                                                                </div>

                                                                <div style={{ width: '300px', textAlign: 'left', overflowWrap: 'break-word', maxWidth: '100%' }}>{message.media_id}.{getFileExtension(message.media_url_path)}</div>
                                                            </div>
                                                        )}


                                                        {message.message_type === 'video' && message.media_url_path && (
                                                            <div style={{ position: 'relative', display: 'inline-block', width: '300px', height: '200px', maxWidth: '100%' }}>
                                                                <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '5px', overflow: 'hidden', cursor: 'pointer' }} onClick={() => downloadBtnClick(message.media_id, index)} >

                                                                    <video height='auto' controls autoplay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                                                                        <source src={message.media_url_path} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>

                                                                    {/* Overlay for the download button */}
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                        zIndex: 20,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        {/* Download button icon */}
                                                                        {loadingIndex === index ? (
                                                                            <PuffLoader color="#12ff05" size={150} />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '300px', textAlign: 'left', overflowWrap: 'break-word', maxWidth: '100%' }}>{message.media_id}.{getFileExtension(message.media_url_path)}</div>

                                                            </div>
                                                        )}

                                                        <div style={{ textAlign: 'left', maxWidth: '300px', height: 'auto' }}>
                                                            <pre style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', whiteSpace: 'pre-wrap', width: '100%', wordWrap: 'break-word' }}>
                                                                {message.whatsapp_message && message.message_type === 'template'
                                                                    ? (message.whatsapp_message?.startsWith('"') && message.whatsapp_message?.endsWith('"')
                                                                        ? message.whatsapp_message?.slice(1, -1).replace(/\\r\\n|\\n|\\r/g, '\n')
                                                                        : message.whatsapp_message?.replace(/\\r\\n|\\n|\\r/g, '\n'))
                                                                    : message.whatsapp_message?.replace(/\\r\\n|\\n|\\r/g, '\n')}
                                                            </pre>
                                                        </div>

                                                        <div style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            display: "flex",
                                                            flexDirection: 'column',
                                                            alignItems: 'center'
                                                        }}>
                                                            {(message.url_btn_name) && <div style={{
                                                                width: '100%',
                                                                padding: 2,
                                                                justifyContent: 'center',
                                                                // fontWeight: 'bold',
                                                                display: 'flex',
                                                                paddingBottom: '5px',
                                                                color: '#00BFFF',
                                                                borderTop: '1px solid #ccc',
                                                                alignItems: 'center'
                                                            }}>
                                                                {/* <VscLinkExternal style={{ marginRight: '5px' }} />{message.url_btn_name} */}
                                                                <a href={`https://${message.url_link}`} target='_blank' style={{
                                                                    width: '100%',
                                                                    textDecoration: 'none',
                                                                    padding: 2,
                                                                    justifyContent: 'center',
                                                                    // fontWeight: 'bold',
                                                                    display: 'flex',
                                                                    paddingBottom: '5px',
                                                                    color: '#00BFFF',
                                                                    alignItems: 'center'
                                                                }}> <VscLinkExternal style={{ marginRight: '5px' }} />{message.url_btn_name} </a>
                                                            </div>}
                                                            {(message.btn_name) && <div style={{
                                                                width: '100%',
                                                                padding: 2,
                                                                justifyContent: 'center',
                                                                // fontWeight: 'bold',
                                                                padding: '5px',
                                                                display: 'flex',
                                                                marginBottom: '10px',
                                                                borderTop: '1px solid #ccc',
                                                                color: '#00BFFF',
                                                                alignItems: 'center'
                                                            }}>
                                                                <MdLocalPhone style={{ marginRight: '5px' }} />{message.btn_name}
                                                            </div>}
                                                        </div>


                                                        <div style={{ textAlign: 'right', maxWidth: '300px' }}>
                                                            {/* <p style={{ fontSize: '12px', color: '#888', margin: '5px 0', marginTop: '5px' }}>{moment(message.updated_at).format('hh:mm A')} */}
                                                            <p style={{ fontSize: '12px', color: '#888', margin: '5px 0', marginTop: '5px' }}>{showChatDateTime(message.updated_at)}
                                                                {message.message_nature === "Outgoing" && (
                                                                    message.status === 'read' ? (
                                                                        <RiCheckDoubleFill style={{ marginLeft: '5px', fontSize: '14px', color: 'rgb(0, 191, 255)' }} />
                                                                    ) : message.status === 'delivered' ? (
                                                                        <RiCheckDoubleFill style={{ marginLeft: '5px', fontSize: '14px', color: 'grey' }} />
                                                                    ) : (message.status === 'accepted' || message.status === 'sent') ? (
                                                                        <RiCheckFill style={{ marginLeft: '5px', fontSize: '14px', color: 'grey' }} />
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                )}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}

                                        </div>))}
                                {attachmentclicked && (
                                    <div style={popupStyles}>
                                        <button style={buttonStyle} onClick={() => handleButtonClick('image/*', handleFileChange)}>
                                            <PiImagesLight size={22} />
                                            <div style={iconTextStyle}>Image</div>
                                        </button>
                                        <button style={buttonStyle} onClick={() => handleButtonClick('video/*', handleVideoChange)}> {/* Added comment */}
                                            <ImFileVideo size={22} />
                                            <div style={iconTextStyle}>Video</div>
                                        </button>
                                        <button style={buttonStyle} onClick={() => handleButtonClick('.doc,.docx,.txt,.pdf', handleDocumentChange)}>
                                            <IoDocumentTextOutline size={22} />
                                            <div style={iconTextStyle}>Document</div>
                                        </button>
                                        <button style={buttonStyle} onClick={handletemplateiconclicked}>
                                            <LuMailPlus size={22} />
                                            <div style={iconTextStyle}>Template</div>
                                        </button>
                                    </div>
                                )}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileInputChange} // Trigger handleFileInputChange
                                />

                            </div>
                        </div>

                        {/* {IsMessagePresent && (<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', top: '92%', position: 'absolute', zIndex: 9999 }}> */}
                        {IsMessagePresent && (<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', bottom: '0', position: 'absolute', zIndex: 9999 }}>
                            <div style={{ width: '100%', marginLeft: '18px' }}>
                                <Form>
                                    <Form.Group className="mb-3" style={{ userSelect: "none", position: 'sticky' }}>
                                        <Form.Control
                                            as='textarea'
                                            // type="text"
                                            ref={textareaRef}
                                            rows={1}
                                            // maxLength={4000}
                                            placeholder="Type a message to send"
                                            style={{
                                                overflowY: 'auto',
                                                overflowX: 'auto',
                                                scrollbarWidth: 'thin',
                                                height: '41px',
                                                width: '100%',
                                                paddingRight: '45px',
                                                maxHeight: '4.5em',
                                                minHeight: '1.5em',
                                                borderRadius: '5px',
                                                border: '1px solid rgb(229, 228, 226)',
                                                backgroundColor: '#FFFFFF',
                                                // wordWrap: 'break-word',                                               
                                                wordBreak: 'break-all',
                                                whiteSpace: 'pre-wrap',
                                                resize: 'none',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',

                                            }}
                                            onFocus={(e) => { e.target.style.outline = 'none'; e.target.style.borderColor = '#80bdff'; e.target.style.boxShadow = '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'; }}
                                            onBlur={(e) => { e.target.style.outline = 'none'; e.target.style.borderColor = '#ced4da'; e.target.style.boxShadow = 'none'; }}
                                            onMouseDown={(e) => { e.target.style.outline = 'none'; e.target.style.borderColor = '#80bdff'; e.target.style.boxShadow = '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'; }}
                                            onMouseUp={(e) => { e.target.style.outline = 'none'; e.target.style.borderColor = '#ced4da'; e.target.style.boxShadow = 'none'; }}
                                            value={chatMessage}
                                            maxLength={3500}
                                            onChange={(e) => {
                                                handleChatMessage(e);
                                                e.target.style.height = 'auto'; // Reset height to auto to calculate new height
                                                e.target.style.height = `${Math.max(41, Math.min(e.target.scrollHeight, 4.5 * parseFloat(getComputedStyle(e.target).lineHeight)))}px`; // Set new height based on scroll height, limited to 3 lines
                                                e.target.style.overflow = e.target.scrollHeight > 4.5 * parseFloat(getComputedStyle(e.target).lineHeight) ? 'auto' : 'hidden'; // Toggle overflow based on content height
                                            }}


                                            onKeyDown={handleKeyDown}

                                        />

                                        <GrAttachment
                                            size={22}
                                            style={{
                                                color: 'black',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                opacity: '0.5',
                                                marginLeft: '1rem'
                                            }}
                                            onClick={handleattachment}
                                        />
                                    </Form.Group>
                                </Form>
                            </div>
                            {/* <Button style={{ opacity: 1, marginLeft: '15px', marginRight: collapsed ? '38px' : '18px', marginBottom: '18px', height: '40px', width: '45px', backgroundColor: (chatMessage || selectedImagePreview || selectedVideo || selectedDocument) ? 'rgb(63, 144, 109)' : '#FFFFFF', border: '1px solid rgb(229, 228, 226)' }} disabled={!(chatMessage || selectedImagePreview || selectedVideo || selectedDocument)} onClick={handleSendMessage}>
                                <FiSend size={22} style={{ color: (chatMessage || selectedImagePreview || selectedVideo || selectedDocument) ? 'white' : 'black' }} />
                            </Button> */}

                            <Button style={{ opacity: 1, marginLeft: '15px', marginRight: collapsed ? '38px' : '18px', marginBottom: '18px', height: '40px', width: '45px', backgroundColor: (chatMessage || (selectedImagePreview && !loading) || (selectedVideo && !loading) || (selectedDocument && !loading)) ? 'rgb(63, 144, 109)' : '#FFFFFF', border: '1px solid rgb(229, 228, 226)' }} disabled={!(chatMessage || (selectedImagePreview && !loading) || (selectedVideo && !loading) || (selectedDocument && !loading))} onClick={handleSendMessage}>
                                <FiSend size={22} style={{ color: (chatMessage || (selectedImagePreview && !loading) || (selectedVideo && !loading) || (selectedDocument && !loading)) ? 'white' : 'black' }} />
                            </Button>

                            {isTemplatePopupVisible && (
                                <div style={{ position: 'absolute', zIndex: 100000, width: '100%', height: '100%' }}>
                                    <TemplatePopup onClose={handleCloseTemplatePopup} selectedContact={selectedContact} contactIndex={contactIndex} moveContact={moveContact} />
                                </div>
                            )}
                        </div>
                        )}

                        {!IsMessagePresent && (
                            <div style={{
                                // width: collapsed ? '96vh' : '79vh'
                                userSelect: 'none', width: '100%'
                                , backgroundColor: '#ffffff', padding: '9px', paddingTop: '11px', border: '1px solid #d1d1d1', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute',
                                //  top: '91.5vh'
                                bottom: 0,
                                zIndex: 9999

                            }}>
                                <div>
                                    Reply window closed
                                </div>
                                <Button variant="success" style={{ display: 'flex', alignItems: 'center', color: 'white', borderRadius: '5px' }} disabled={!selectedContact} onClick={handleSendTemplateClick}>
                                    <LuSend style={{ marginRight: '5px', color: 'white' }} />
                                    Send Template
                                </Button>
                                {isPopupVisible && <TemplatePopup onClose={handleClosePopup} selectedContact={selectedContact} contactIndex={contactIndex} moveContact={moveContact} />}

                            </div>
                        )}


                    </div>
                </Col>)}

                {selectedContact && (<Col xs={2} id='details' style={{ padding: 0, border: '1px solid #E5E4E2', height: `calc(100vh - ${navbarHeight}px)` }}>
                    <div style={{ height: '40px', width: '100%', padding: 8, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <div >
                            <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} onClick={() => setDetailNoteBtn("Details")}>Details</Button>
                        </div>
                        <div >
                            <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} onClick={noteBtnHandler}>Notes</Button>
                        </div>
                    </div>

                    {detailNoteBtn == "Details" && <div style={{ padding: 10, borderTop: '2px solid green', borderTop: 'none', position: 'relative' }}>
                        <div style={{
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '50%',
                            height: '2px',
                            backgroundColor: 'green'
                        }}></div>
                        <div style={{ userSelect: 'none', lineHeight: '1', }}>
                            <h6>Customer Name</h6>
                            <p style={{ wordBreak: 'break-word' }}>{currentUser.first_name} {currentUser.last_name}</p>
                            <hr />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: '0.5', marginTop: '25px', marginBottom: '25px' }}>
                            <h6 style={{ userSelect: 'none', marginBottom: '5px' }}>Phone number</h6>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginRight: '5px', marginBottom: '0' }}>{currentUser.mobile}</p>
                                {/* <IoIosCall style={{ color: 'green', alignSelf: 'center' }} /> */}
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h6 style={{ marginBottom: '5px' }}>Contacted on WABA</h6>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginRight: '5px', marginBottom: '0' }}>{currentUser.mobile}</p>
                                <FaWhatsapp style={{ color: 'green', alignSelf: 'center' }} />
                            </div>
                        </div> */}
                    </div>}

                    {detailNoteBtn == "Notes" && <div style={{ padding: 10, borderTop: '2px solid green', borderTop: 'none', position: 'relative' }}>
                        <div style={{
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            width: '50%',
                            height: '2px',
                            backgroundColor: 'green'
                        }}></div>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} maxLength={500} placeholder='Enter note text' onChange={noteHandler} value={noteDescription} style={{ outline: 'none', boxShadow: 'none', resize: 'none', backgroundColor: ' #EAF3F0' }} />
                            </Form.Group>
                        </Form>
                        <Button style={{ padding: '5px 10px', fontSize: '12px' }} variant="success" onClick={() => saveNote()} disabled={allTrue()}>ADD NOTE</Button>
                    </div>}


                    {detailNoteBtn === "Notes" && notesData.length > 0 && (
                        <div style={{ marginTop: 10, padding: '10px', width: '100%', height: '60vh', position: 'relative', }}>
                            <div style={{ padding: 10, width: '100%', height: '60vh', border: '1px solid #E5E4E2', position: 'relative', borderRadius: '10px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                                {notesData.map((item, index) => (

                                    <div key={index} style={{ padding: '10px', width: '100%', height: 'auto', borderBottom: '1px solid #E5E4E2', wordBreak: 'break-word' }}>

                                        <div>
                                            <p><MdOutlineSpeakerNotes size={22} /> {item.description}</p>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                            <p style={{ color: '#8c8a8a', margin: '0', padding: '0' }}> {moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</p>
                                        </div>

                                    </div>

                                ))}

                            </div>

                        </div>
                    )}


                </Col>)}
            </Row>
            <SnackbarProvider style={{ marginTop: '15%' }} />
        </Container>
    )
}


export default Layout
